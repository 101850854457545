<template>
  <section class="main-section">
    <div class="main-layout" v-if="items.length">
      <div class="spaces-card" v-for="(c, i) in items" :key="i">
        <a :href="'/space-' + c.name">
          <div class="spaces-title">{{ c.title }}</div>
          <div class="spaces-desc">{{ c.desc }}</div>
          <div class="spaces-img-div">
            <div class="spaces-img"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/space-' + c.name + '.png'"/></div>
            <div class="spaces-label"><img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/space-label.png"/></div>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        name: 'house',
        title: 'MAIER 全屋定制',
        desc: '打造您的专属优雅'
      },
      {
        name: 'cabinet',
        title: 'MAIER 橱柜系列',
        desc: '简约而不简单'
      },
      {
        name: 'closet',
        title: 'MAIER 衣帽系列',
        desc: '精致生活空间'
      },
      {
        name: 'storage',
        title: 'MAIER 收纳系列',
        desc: '每一处尽显巧思'
      }
    ]
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
